import React, {useState} from "react";
import instance from "../../api/api";
import InputCreate from "../../features/InputCreate";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {ColorsTheme} from "../../utils/themesColors"
import Button from "../../features/Button";
// import { MultiSelect } from 'primereact/multiselect';
// import { Dropdown } from 'primereact/dropdown';


function PromotionsCreate() {
    const styleBtn = {
                padding: ".5em",
                borderRadius: "5px",
                fontWeight: "bold",
                cursor: "pointer",
                color: "white",
              backgroundColor: ColorsTheme.primary,
                marginTop: "1em",
                border: "none",
              };
      const MySwal = withReactContent(Swal);
      
      const [data, setData] = useState( {
        libelle:"",
        date:"",
        message:""
      });
      const [isLoading, setIsLoading] = useState(false);
     
    
      const createEvent = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
         
          const response = await instance.post("evenements",data);
         
          console.log(response)
          if (response.data.status === false) {
            let msgError = []
            for (const property in response.data.message) {
              msgError = [...msgError,response.data.message[property]]
            }
            MySwal.fire({
              title: msgError,
              icon: "info",
              showConfirmButton: true,
            });
            setIsLoading(false);
          }else{
            setIsLoading(false);
            setData( {expiration:"",pourcentage:"",code:""});
            MySwal.fire({
              title: "Enregistrement reussi.",
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } catch (error) {
          console.log(error);
          let msgError = []
          for (const property in error.response.data.message) {
          
            msgError = [...msgError,error.response.data.message[property]]
          }
          MySwal.fire({
            title: msgError,
            icon: "info",
            showConfirmButton: true,
          });
          setIsLoading(false);
        }
      };
   
      
      return (
        <div className="create-user">
            <h1>Enregistrer un événement</h1>
          <form onSubmit={createEvent}>
            <InputCreate
              label="Titre"
              typeInput="text"
              nameInput="titre"
              active={true}
              getValue={((e)=>{
                setData({ ...data,libelle: e.target.value });
              })}
              dataValue={data.libelle}   
            />
            {/* <InputCreate
              label="Message"
              typeInput="text"
              nameInput="titre"
              active={true}
              getValue={((e)=>{
                setData({ ...data,libelle: e.target.value });
              })}
              dataValue={data.libelle}   
            /> */}
            <label htmlFor="event">Messages</label>
            <textarea className="w-100"
      onChange={(e)=>{
        setData({...data,message:e.target.value})
    }}
      name="" id="" cols="30" rows="10"></textarea>
            <InputCreate
              label="Date"
              typeInput="date"
              nameInput="titre"
              active={true}
              getValue={((e)=>{
                setData({ ...data,date: e.target.value });
              })}
              dataValue={data.date}   
            />
            <div>
              <Button titre="Enregistrer" styleBtn={styleBtn} isLoading={isLoading} />
            </div>
          </form>
        </div>
      );
}

export default PromotionsCreate
