import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import instance from "../../api/api";
// import Input from "../../features/Input";
import Button from "../../features/Button";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// import Spinner from "react-bootstrap/Spinner";
// import Table from "react-bootstrap/Table";
import { Tabs } from "antd-mobile";
// import {Help} from "../../utils"




function CouponsDetail() {
  const styleBtn = {
    padding: ".5em",
    borderRadius: "5px",
    fontWeight: "bold",
    cursor: "pointer",
    color: "black",
    backgroundColor: "rgb(255 177 47 / 99%)",
    marginTop: "1em",
    border: "none",
  };
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const { id } = useParams();
  const [detailCoupons, setDetailCoupons] = useState({});
  const [isLoading, setIsLoading] = useState(false);
const [data,setData] = useState({
  tarif: "",
  duree: "",
  libelle: "",
  description: "",
  path: []
})

  
  useEffect(() => {
   
    const getCouponsDetail = async () => {
      try {
        const response = await instance.get(`/coupons/${id}`);
        console.log(response);
        const coupons = response.data.data;
        setDetailCoupons(coupons);
      } catch (error) {
        console.error("Une erreur s'est produite : ", error);
      }
    };
    getCouponsDetail();
  }, [id]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await instance.put(`coupons/${id}`,data);
      console.log(response);
      if (!response.data.status) {
        setIsLoading(false);
        MySwal.fire({
          title: "Erreur.",
          icon: "info",
          showConfirmButton: true,
        });
      }
      MySwal.fire({
        title: "Modification effectuée avec succès.",
        icon: "success",
 showConfirmButton: true,
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      MySwal.fire({
        title: error.data.message,
        icon: "success",
 showConfirmButton: true,
      });
    }
  };
  if(!detailCoupons.created_at) return(
    <div className="detail-student"> 
    <h5 className="mt-5 text-center">Chargement...</h5>
    </div>
  ); 
  return (
    <div className="detail-student">
      <div className="back-user">
        <IoChevronBackCircleOutline
          className="back-home"
          onClick={() => {
            navigate(-1);
          }}
        />

        <h5>
          <span className="text-primary">Coupons</span> /{" "}
          <span className="text-primary">Détail</span>
        </h5>
      </div>
        <div>
        {
           detailCoupons.user ?  <p>Client : {detailCoupons.user.nom} 
           {detailCoupons.user.prenom} (Numéro de téléphone : {detailCoupons.user.phone}) </p>:
           <p className="badge bg-warning">Coupon gratuit</p>  
          }
          <p>Code : {detailCoupons.code} </p>
          <p>Pourcentage : {detailCoupons.pourcentage} %</p>
          <p>Expiration : {new Date(detailCoupons.expiration).toLocaleDateString('fr')}</p>
          
         
        </div>
    </div>
  );
}

// function GetPersonnel(props) {
//   const {coupons} = props
//   const personnel = []
 
//   for(const item in coupons){
//     if((item === "assistant_comptable" 
//     || item === "responsable_couponss" 
//     || item === "supervisers") && coupons[item] !== null){
//       console.log(coupons[item])
//       personnel.push(coupons[item])
//     }
//     if(item === "supervisers" && coupons[item] !== null){
//       coupons[item].vendeurs.forEach(element => {
//         personnel.push(element)
//        });

//     }
//   }
//   console.log("personnel",personnel)
// //   if(personnel[0] === null) return(
// // <tbody className="text-center">
// //   <tr>
// //     <td colSpan={5}>Pas de donnée.</td>
// //     </tr>
// // </tbody>
// //   );
       
//     return(
//       <tbody className="text-center">
// {personnel.length > 0 ? (
//                     personnel.map((item) => (
//                       <tr key={item.id}>
//                         <td>{item.lastname}</td>
//                         <td>{item.firstname}</td>
//                         <td>{item.phone1}</td>
//                         <td>{item.email}</td>
//                         <td>{item.role ? item.role.label:null}</td>
//                       </tr>
//                     ))
//                   ) : (
//                     <tr className="w-100">
//                       <td colSpan="5" className="text-center">
//                         Pas de donnée.
//                       </td>
//                     </tr>
//                   )}
// </tbody>
//     )
// }


// function GetStock(props) {
//   const {coupons} = props
//   const stock = [] 
//   for(const item in coupons){
//     if(item === "responsable_couponss" && coupons[item] !== null){
//       coupons[item].requetes.forEach(element => {
//         if(element.livraison === "Livrer"){
//           stock.push(element)
//         }
//        });

//     }
//   }
//   console.log("stock",stock)
  
       
//     return(
//       <tbody className="text-center">
        
// {stock.length > 0 ? (
//                     stock.map((item) => (
//                       <tr key={item.id}>
//                         <td>{new Date(item.created_at).toLocaleDateString("fr")}</td>
//                         <td className="position-relative">
//                           {Help.calculeTotal(item.produits)}
//                           </td>
//                         <td className="position-relative">
//                           {
//                             item.produits.map(prod=>{
//                               return(
//                                 <span key={prod.id} className="position-absolute span-tableau">{prod.libelle}</span>
//                               )
//                             })
//                           }
//                           </td>
                          
                          
//                         <td className="position-relative">{
//                             item.produits.map(prod=>{
//                               return(
//                                 <span key={prod.id} className="position-absolute span-tableau">{prod.pivot.nombre}</span>
//                               )
//                             })
//                           }</td>
//                         <td className="position-relative">
//                           {
//                             item.produits.map(prod=>{
//                               return(
//                                 <span key={prod.id} className="position-absolute span-tableau">{prod.pivot.nombre_total}</span>
//                               )
//                             })
//                           }</td>

                      
                          
//                           <td className="position-relative">
//                           {
//                             item.produits.map(prod=>{
//                               return(
//                                 <span key={prod.id} className="position-absolute span-tableau">
//                                   {Number(prod.pivot.nombre)-Number(prod.pivot.nombre_total)}</span>
//                               )
//                             })
//                           }
//                           </td>

//                       </tr>
//                     ))
                
//                   ) : (
//                     <tr className="w-100">
//                       <td colSpan="6" className="text-center">
//                         Pas de donnée.
//                       </td>
//                     </tr>
//                   )}
// </tbody>
//     )
// }

export default CouponsDetail;
