import React, {useState} from "react";
import instance from "../../api/api";
import InputCreate from "../../features/InputCreate";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Button from "../../features/Button";
import {ColorsTheme} from "../../utils/themesColors"

function CreateService() {
    const styleBtn = {
            padding: ".5em",
            borderRadius: "5px",
            fontWeight: "bold",
            cursor: "pointer",
            color: "white",
          backgroundColor: ColorsTheme.primary,
            marginTop: "1em",
            border: "none",
          };
      const MySwal = withReactContent(Swal);
      
      const [data, setData] = useState(  {
        nom: "",
        prenom: "",
        email: "",
        // password: "",
        phone: "",
        role_id: 2,
        date_of_birt: ""
      });
      const [isLoading, setIsLoading] = useState(false);
    
      
    
      const createNewCustom = async (e) => {
        e.preventDefault();
        console.log("RESUME DATA",data)
        setIsLoading(true);
        try {
         
          const response = await instance.post("users",data);
         
          console.log(response)
          if (response.data.status === false) {
            let msgError = []
            for (const property in response.data.message) {
              msgError = [...msgError,response.data.message[property]]
            }
            MySwal.fire({
              title: msgError,
              icon: "info",
              showConfirmButton: true,
            });
            setIsLoading(false);
          }else{
            setIsLoading(false);
            setData(  {
              nom: "",
              prenom: "",
              email: "",
              phone: "",
              role_id: 2,
              date_of_birt: ""
            });
            MySwal.fire({
              title: "Enregistrement reussi.",
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } catch (error) {
          console.log(error);
          let msgError = []
          for (const property in error.response.data.message) {
          
            msgError = [...msgError,error.response.data.message[property]]
          }
          MySwal.fire({
            title: msgError,
            icon: "info",
            showConfirmButton: true,
          });
          setIsLoading(false);
        }
      };

      
      
      return (
        <div className="create-user">
            <h1>Enregistrer un client</h1>
          <form onSubmit={createNewCustom}>
            <InputCreate
              label="Nom"
              typeInput="text"
              nameInput="nom"
              active={true}
              getValue={((e)=>{
                setData({ ...data, nom: e.target.value });
              })}
              dataValue={data.nom}
            
            />
            
            <InputCreate
              label="Prénoms"
              typeInput="text"
              nameInput="prenom"
              active={true}
              getValue={((e)=>{
                setData({ ...data, prenom: e.target.value });
              })}
              dataValue={data.prenom}
  
            />
            <InputCreate
              label="Email"
              typeInput="email"
              nameInput="email"
              active={true}
              getValue={((e)=>{
                setData({ ...data, email: e.target.value });
              })}
              dataValue={data.email}
              
            />
            <InputCreate
              label="Contact"
              typeInput="text"
              nameInput="contact"
              active={true}
              getValue={((e)=>{
                setData({ ...data, phone: e.target.value });
              })}
              dataValue={data.phone}
              
            />
            <InputCreate
              label="Date d'anniversaire"
              typeInput="date"
              nameInput="date"
              active={true}
              getValue={((e)=>{
                setData({ ...data, date_of_birt: e.target.value });
              })}
              dataValue={data.date_of_birt}
            />
            <div>
              <Button titre="Créer" styleBtn={styleBtn} isLoading={isLoading} />
            </div>
          </form>
        </div>
      );
}

export default CreateService
