import React, { useRef, useState, useEffect } from "react";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { NavLink } from "react-router-dom";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { Button } from 'primereact/button'
import ButtonFeature from "../../features/Button.jsx"
import "primeicons/primeicons.css";

import { AiFillEye,AiFillDelete } from "react-icons/ai";

import { TabView, TabPanel } from "primereact/tabview";
import instance from "../../api/api.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// import Spinner from 'react-bootstrap/Spinner';
// import DetailUser from "../DetailUser.jsx";

function GroupeList() {
  const styleBtn = {
    padding: ".5em",
    borderRadius: "5px",
    fontWeight: "bold",
    cursor: "pointer",
    color: "black",
    backgroundColor: "rgb(255 177 47 / 99%)",
    marginTop: "1em",
    border: "none",
  };
  const MySwal = withReactContent(Swal);

      const [groupe, setGroupe] = useState([]);
      const [groupeId, setGroupeId] = useState("");
      const [showModal, setShowModal] = useState(false);
        const [isLoading, setIsLoading] = useState(true);
     
  //       const action = ({ id }) => {
  //   const deleteService = ()=>{
  //     setGroupeId(id);
  //     console.log(groupeId);
  //     setShowModal(true);
  //   }
  //   return (
  //     <button
  //     className="btn bg-danger text-light fw-bold"
  //       onClick={deleteService}
  //     >
  //      <AiFillDelete /> Supprimer
  //     </button>
  //   );
  // };
    const getListOfGroups = async () => {
    try {
      const response = await instance.get("groupes");
      console.log(response.data);
      console.log(response.data.data);
      setGroupe(response.data.data);
      setIsLoading(false)
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getListOfGroups();
  }, []);

  // const removeCondition = async (e) => {
  //   e.preventDefault()
  //   setIsLoading(true)
  //   try {
  //     const response = await instance.delete(`conditions/${groupeId}`);
  //     console.log("RESPONSE",response)
  //     if (response.data.status === false){
  //       MySwal.fire({
  //         title: response.data.message,
  //         icon: "info",
  //         showConfirmButton: true,
  //       });
  //       setIsLoading(false)
    
  //     }else{
  //       MySwal.fire({
  //         title: response.data.message,
  //         icon: "info",
  //         showConfirmButton: true,
  //       });
  //       setGroupeId("");
  //       setShowModal(false);
  //       setIsLoading(false)
  //       getListOfGroups();
      
  //     }
     
  //   } catch (error) {
  //     console.log(error);
  //     MySwal.fire({
  //       title: error.data.message,
  //       icon: "info",
  //       showConfirmButton: true,
  //     });
  //   }
  // };
if(isLoading) return <h1 className="my-5 text-center">Chargement...</h1>
  return (
       <div className="conteneur-page">
           {/* {showModal ? (
        <div className="conteneur-modal">
          <form onSubmit={removeCondition}>
            <h5 className="m-2">Voulez-vous supprimer cette condition ?</h5>
               <ButtonFeature titre="Confirmer" styleBtn={styleBtn} isLoading={isLoading} />
            <button
            className="btn-lg bg-danger mx-2 border-0 rounded-3 p-2 text-light fw-bold"
              onClick={(e) => {
                e.preventDefault()
                setShowModal(false);
              }}
            >
              Annuler
            </button>
          </form>
        </div>
      ) : null}  */}
      <h1>Liste des groupes</h1>
      <GroupeDatatable items={groupe}   />
    </div>
   
  )
}
export function GroupeDatatable({items}) {

  const dt = useRef(null);

  
  const footer = `${items ? items.length : 0} groupes.`;

  const detailsInfo = (rowData) => {
    const styleBtn = {
      textDecoration: "none",
      cursor: "pointer",
      fontSize: "1em",
      color: "black",
    };
    return (
      <NavLink to={`/groupe/${rowData.id}`} style={styleBtn}>
        <AiFillEye fontSize="1.5em" />
      </NavLink>
    );
  };

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    libelle: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    tarif: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    duree: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const renderHeader = () => {
    const conteneurSearch = {
      padding: "1.3em",
    };
    // const exportExcel = () => {
    //   import("xlsx").then((xlsx) => {
    //     const worksheet = xlsx.utils.json_to_sheet(items);
    //     const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
    //     const excelBuffer = xlsx.write(workbook, {
    //       bookType: "xlsx",
    //       type: "array",
    //     });

    //     saveAsExcelFile(excelBuffer, "files");
    //   });
    // };

    // const saveAsExcelFile = (buffer, fileName) => {
    //   import("file-saver").then((module) => {
    //     let EXCEL_TYPE =
    //       "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    //     let EXCEL_EXTENSION = ".xlsx";
    //     const data = new Blob([buffer], {
    //       type: EXCEL_TYPE,
    //     });

    //     module.default.saveAs(
    //       data,
    //       fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    //     );
    //   });
    // };
    return (
      <div className="conteneur-search">
        {/* <div className="btn-upload-file">
          <Button
            type="button"
            label="Excel format"
            icon="pi pi-file-excel"
            style={{
              border: "none",
              color: "white",
              margin: "1em 0",
            }}
            onClick={exportExcel}
            data-pr-tooltip="XLS"
          />
        </div> */}

        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Keyword Search"
          style={conteneurSearch}
        />
      </div>
    );
  };
  const header = renderHeader();
  

  return (
    <div>
          <TabView>
      <TabPanel>
          <DataTable
            ref={dt}
            value={items}
            footer={footer}
            tableStyle={{ minWidth: "50rem" }}
            paginator
            rows={10}
            filters={filters}
            globalFilterFields={["nom", "contact", "email"]}
            header={header}
          >
            <Column field="libelle" style={{textAlign:"center"}} header="Groupe"></Column>
            {/* <Column field="periode" style={{textAlign:"center"}} header="Période"></Column> */}
            <Column field="id" style={{textAlign:"center"}} 
            header="Détail" body={detailsInfo}></Column>
            {/* <Column field="id" style={{textAlign:"center"}}
             header="Action" body={action}></Column> */}
          </DataTable>
        </TabPanel>
        </TabView>
    </div>
  );
}

export default GroupeList
