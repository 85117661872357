import React, { useRef, useState, useEffect } from "react";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { NavLink } from "react-router-dom";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
// import { Button } from "primereact/button";
// import ButtonFeature from "../../features/Button"
// import { BiSolidUserDetail } from "react-icons/bi";
import "primeicons/primeicons.css";

import { AiFillEye } from "react-icons/ai";

import { TabView, TabPanel } from "primereact/tabview";
import instance from "../../api/api.js";
// import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";
// import Spinner from "react-bootstrap/Spinner";



function Messages() {

  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getListMessage = async () => {
      
      try {
        const response = await instance.get("messages");
        console.log("messages", response);
        setMessages(response.data.data);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    getListMessage();
  }, []);

  if(isLoading) return <h1 className="my-5 text-center">Chargement...</h1>
  return (
    <div className="conteneur-page">
      <h1>Messages</h1>
      <MessageDatatable
        items={messages}
      />
    </div>
  );
}
export function MessageDatatable({ items }) {
  const dt = useRef(null);

  const footer = `${items ? items.length : 0} messages.`;

  const getNameClient = (rowData)=>{
   return (
    rowData.user ? <p>{rowData.user.nom} {rowData.user.prenom}</p>:""
    );
  }
  const getDate = (rowData)=>{
    return (
        <p>
            <span>{new Date(rowData.created_at).toLocaleDateString('fr')}</span>
        </p>
       );
  }

  const detailsInfo = (rowData) => {
    const styleBtn = {
      textDecoration: "none",
      cursor: "pointer",
      fontSize: "1em",
      color: "black",
    };
    return (
      <NavLink to={`/reservations/${rowData.id}`} style={styleBtn}>
        <AiFillEye fontSize="1.5em" />
      </NavLink>
    );
  };
  // const getConnected = (rowData)=>{
  //   return(
  //     <div 
  //     className="is-dispo"
  //     style={{
  //       background:statusConfig[rowData.status]
  //     }}
  //     >
  //     </div>
  //   )
  // }
  // const getDateAndHour = (rowData)=>{
  //   return(
  //     <span>
  //       {
  //         rowData.date
  //       } à {
  //         rowData.heure
  //       }
  //     </span>
  //   )
  // }

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    nom: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    phone: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const renderHeader = () => {
    const conteneurSearch = {
      padding: "1.3em",
    };
    return (
      <div className="conteneur-search">
     

        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Keyword Search"
          style={conteneurSearch}
        />
      </div>
    );
  };
  const header = renderHeader();

  return (
    <div>
        <TabView>
          <TabPanel>
            <DataTable
              ref={dt}
              value={items}
              footer={footer}
              tableStyle={{ minWidth: "50rem" }}
              paginator
              rows={10}
              filters={filters}
              globalFilterFields={["user", "service", "montant", "date"]}
              header={header}
            >
              <Column
                field="id"
                style={{ textAlign: "center" }}
                header="Date"
                body={getDate}
              ></Column>
              <Column
                field="user"
                style={{ textAlign: "center" }}
                header="Client"
                body={getNameClient}
              ></Column>
              <Column
                field="objet"
                style={{ textAlign: "center" }}
                header="Objet"
              ></Column>
             
              <Column
                field="id"
                style={{ textAlign: "center" }}
                header="Détail"
                body={detailsInfo}
              ></Column>

            </DataTable>
          </TabPanel>
        </TabView>
    </div>
  );
}

export default Messages;
